

.Screen {
  image-rendering: pixelated; /* webkit */
  image-rendering: optimizespeed; /* firefox */
}

.RunPage {
  overflow: hidden;
}

.RunPage .screen-container {
  text-align: center;
}

